<template>
  <div class="indexBox">
    <div class="topBox">
      <div @click="show = true"></div>
      <div>名校</div>
    </div>
    <div class="searchDiv">
      <img src="../../image/searchImg.png" alt />
      <input
        type="text"
        @focus="onFocusr()"
        v-model="schoolObj.schoolName"
        placeholder="搜索"
      />
      <img
        class="seaDel"
        src="../../image/searDel.png"
        alt=""
        v-if="schoolObj.schoolName != ''"
        @click="delEvent()"
      />
    </div>
    <van-loading
      id="loading"
      type="spinner"
      size="24px"
      v-if="loading === true"
    />
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <van-list
          v-model="upperLoading"
          :finished="finished"
          :immediate-check="false"
          finished-text="没有更多了"
          offset="500"
          @load="onLoad"
        >
          <div v-if="loading === false">
            <div class="contentBox" v-for="item in schoolList" :key="item.id">
              <div class="contentTop">
                <div class="hear"><img :src="item.logo" alt /></div>
                <div class="schoolBox">
                  <div>{{ item.name }}</div>
                  <div>{{ item.title }}</div>
                </div>
                <div class="zkBtn" @click="goDetail(item)">
                  <img src="../../image/zk.png" alt />
                </div>
              </div>
              <div class="contentBot" v-if="item.infos != null">
                <div class="botBox" v-for="i in item.infos" :key="i.id">
                  <div><span v-if="i.timeShow == '刚刚'">新</span> {{ i.title }}</div>
                  <div @click="detailEvent(i)">查看</div>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    <!-- 左侧弹框 -->
    <van-popup
      v-model="show"
      position="left"
      :style="{ height: '100%', width: '75%' }"
    >
      <div class="popupImg">
        <img src="../../image/lookTitle.png" alt />
      </div>
      <div class="describe">您可以在这里随时切换您感兴趣的地区和学段</div>
      <div class="switch">切换地区</div>
      <div class="cascaderBox">
        <van-field
          v-model="fieldValue"
          is-link
          readonly
          label="省/市（县）"
          placeholder="请选择所在地区"
          @click="cascaderShow = true"
        />
      </div>
      <div class="switchPeriod">切换学段</div>
      <div class="radioBox">
        <div
          class
          v-for="(item, index) in radioList"
          :class="gradActive === item.id ? 'gradActive' : ''"
          @click="gradEvent(item, index)"
          :key="item.id"
        >
          {{ item.value }}
          <img v-if="item.status" src="../../image/blue.png" alt />
        </div>
      </div>
      <div class="popupBtn" @click="popupBtn()">确定</div>
    </van-popup>
    <van-popup v-model="cascaderShow" round position="bottom">
      <van-cascader
        v-model="cascaderValue"
        title="请选择所在地区"
        :options="options"
        active-color="#1989fa"
        @close="cascaderShow = false"
        @finish="onFinish"
        @change="onCascaderChange"
      />
    </van-popup>
  </div>
</template>

<script>
import { Popup } from "vant";
import { Cascader } from "vant";
import { Tab, Tabs } from "vant";
import { Swipe, SwipeItem } from "vant";
import { dictionary, regionList, schoolPage } from "../../api/lookAt";
export default {
  data() {
    return {
      loading: true,
      show: false, //左侧弹框状态
      cascaderShow: false, //所在地区弹框状态
      fieldValue: "", //省市
      cascaderValue: "",
      options: [], //省市列表
      radioList: [], //学段列表
      gradActive: "", //学段选中状态
      schoolObj: {
        pageNum: 1,
        pageSize: 10,
        provinceId: "", //省级id
        cityId: "", //市级id
        period: "", //学段id
        schoolName: this.$route.query.value || "", //名校名称
      },
      schoolList: [], //名校列表
      total: 0,
      upperLoading: false, //上拉加载
      finished: true, //加载与不加载的状态
      listTwo: [], //用于上拉加载时将数据合并
      isLoading: false //下拉加载状态
    };
  },
  mounted() {
    this.loading = true;
    // 地区
    regionList().then((res) => {
      this.options = res.data.data;
      console.log(res.data.data);
      this.options = res.data.data.map((item) => {
        item.text = item.name;
        item.value = item.id;
        if (item.children.length > 0 || item.children != null) {
          item.children.map((i) => {
            i.text = i.name;
            i.value = i.id;
          });
        }
      });
    });
    // 学段
    dictionary({ parentId: 13 }).then((res) => {
      console.log(res, "学段");
      res.data.data.forEach((item) => {
        item.status = false;
      });
      this.radioList = res.data.data;
    });
    this.getschoolPage();
  },
  methods: {
    getschoolPage() {
      this.schoolObj.pageNum = 1
      schoolPage(this.schoolObj).then((res) => {
        console.log(res, "名校");
         if(res.data.code === 0){
          this.schoolList = res.data.data.items;
          this.total = res.data.data.total;
          this.loading = false;
          var num = this.total;
          this.total = num - 0;
           if (this.schoolList.length >= this.total) {
            //将返回数据长度与总条数进行比较控制加载不加载的状态
            this.finished = true;
          } else {
            this.finished = false;
          }
        }
      });
    },
    onFinish({ selectedOptions }) {
      // console.log(selectedOptions);
      this.cascaderShow = false;
      this.fieldValue = selectedOptions.map((option) => option.text).join("/");
    },
    // 地区change事件
    onCascaderChange(value) {
      console.log(value);
      console.log(this.cascaderValue, "++++++++++++");
      if (value.selectedOptions.length === 2) {
        this.schoolObj.provinceId = value.selectedOptions[0].id;
        this.schoolObj.cityId = value.selectedOptions[1].id;
      }
    },
    // 切换学段
    gradEvent(item, index) {
      console.log(item);
      this.schoolObj.period = item.id;
      this.gradActive = item.id;
      this.radioList.forEach((item) => {
        item.status = false;
      });
      this.radioList[index].status = true;
    },
    popupBtn() {
      this.getschoolPage();
      this.show = false;
      console.log(this.schoolObj);
    },
    // 跳转资讯详情
    detailEvent(item) {
      this.$router.push({ path: "/lookDetail", query: { item: item } });
      this.$store.commit("item", item);
    },
    // 名校详情
    goDetail(item) {
      console.log(item);
      this.$router.push({ path: "/schoolDetail", query: { item: item } });
      this.$store.commit("active", 0);
      this.$store.commit("schoolItem", item);
    },
    // goDetail1(item) {
    //   console.log(item);
    //   this.$router.push({ path: "/schoolDetail", query: { item: item } });
    //   this.$store.commit("active", 1);
    //   this.$store.commit("schoolItem", item);
    // },
    onFocusr() {
      this.$router.push({ path: "/schoolSearch" });
    },
    delEvent() {
      this.schoolObj.schoolName = "";
      this.getschoolPage();
    },
       // 下拉加载
    onRefresh() {
      setTimeout(() => {
        this.$toast("刷新成功");
        this.isLoading = false;
        this.getschoolPage();
      }, 1000);
    },
       // 上拉加载
    onLoad() {
      this.schoolObj.pageNum++; //加载时页码递增
      schoolPage(this.schoolObj).then(res => {
        if (res.data.code === 0) {
          this.listTwo = res.data.data.items; //将接口返回赋值data定义的数组
          this.total = res.data.data.total - 0; //将接口返回的总数据条数赋值data中定义的total
          this.listTwo.forEach(item => {
            this.schoolList.push(item);
            this.upperLoading = false;
            // this.finished = false;
          });
          // 加载状态结束 状态更新为false
          this.upperLoading = false;
          if (this.schoolList.length >= this.total) {
            //将返回数据长度与总条数进行比较控制加载不加载的状态
            this.finished = true;
          }
        }
      });
    }
  },
};
</script>

<style scoped>
/deep/#loading {
  text-align: center;
  margin-top: 100px;
}
.indexBox {
  min-height: 100vh;
  background: #f6f8fa;
  padding-bottom: 120px;
}
.topBox {
  height: 88px;
  display: flex;
  align-items: center;
}
.topBox div:nth-child(1) {
  width: 48px;
  height: 48px;
  background-image: url("../../image/ce.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topBox div:nth-child(2) {
  margin-left: 264px;
  font-size: 32px;
  font-weight: 600;
  color: rgba(11, 21, 38, 0.9);
}
.container {
  width: 750px;
  /* background: #f7f8fa; */
}
.searchDiv {
  width: 686px;
  height: 72px;
  background: #ffffff;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  margin-left: 32px;
  display: flex;
  align-items: center;
  /* margin-top: 12px; */
}
.searchDiv img {
  width: 28px;
  height: 28px;
  margin-left: 16px;
}
.searchDiv input {
  width: 520px;
  height: 40px;
  outline: none;
  border: none;
  font-size: 28px;
  margin-left: 16px;
  display: flex;
}
.seaDel {
  margin-left: 32px !important;
}
.contentBox {
  width: 686px;
  /* height: 96px; */
  border-radius: 16px 16px 16px 16px;
  background: #fff;
  margin-left: 32px;
  margin-top: 32px;
  overflow: hidden;
  /* display: flex; */
  /* align-items: center; */
}
.contentTop {
  height: 96px;
  display: flex;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 32px;
}

.hear img {
  width: 96px;
  height: 96px;
  border-radius: 12px 12px 12px 12px;
  margin-left: 32px;
  display: flex;
}
.schoolBox {
  width: 386px;
  margin-left: 20px;
}
.schoolBox div:nth-child(1) {
  width: 300px;
  font-size: 32px;
  font-weight: 400;
  color: #0b1526;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.schoolBox div:nth-child(2) {
  width: 350px;
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  margin-top: 10px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.zkBtn img {
  width: 120px;
  height: 56px;
  margin-top: 16px;
}
.contentBot {
  width: 622px;
  background: #f6f8fa;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  margin-left: 32px;
  margin-bottom: 32px;
  overflow: hidden;
  margin-top: 16px;
}
.botBox {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 8px;
}
.botBox div:nth-child(1) {
  width: 458px;
  font-size: 28px;
  font-weight: 400;
  color: #0b1526;
  display: flex;
  align-items: center;
  margin-left: 24px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.botBox div:nth-child(1) span {
  display: inline-block;
  width: 36px;
  height: 36px;
  background: rgba(9, 87, 195, 0.1);
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  font-size: 20px;
  font-weight: 400;
  color: #0957c3;
  text-align: center;
  line-height: 38px;
  margin-right: 8px;
}
.botBox div:nth-child(2) {
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  margin-right: 24px;
}
/* 左侧弹框 */
.popupImg img {
  width: 190px;
  height: 41px;
  margin-left: 48px;
  margin-top: 102px;
}
.describe {
  width: 464px;
  height: 88px;
  font-size: 28px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  margin-left: 48px;
  margin-top: 32px;
  line-height: 40px;
}
.switch {
  font-size: 32px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  margin-left: 48px;
  margin-top: 122px;
}
.cascaderBox {
  width: 464px;
  height: 140px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 48px;

  border-bottom: 2px solid #ebedf0;
}
.switchPeriod {
  font-size: 32px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  margin-top: 44px;
  margin-left: 48px;
}
.radioBox {
  width: 464px;
  margin-left: 48px;
  margin-top: 48px;
}
.radioBox div {
  font-size: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 96px;
}
.radioBox img {
  width: 32px;
  height: 32px;
}
.gradActive {
  font-size: 28px;
  font-weight: 400;
  color: #0957c3;
}
.popupBtn {
  width: 464px;
  height: 90px;
  background: #0957c3;
  border-radius: 1066px 1066px 1066px 1066px;
  font-size: 28px;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  line-height: 90px;
  margin: 0 auto;
  margin-top: 100px;
}
.van-popup--left {
  width: 560px;
  height: 1624px;
  background-image: url("../../image/ceback.png");
}
/deep/.van-cell {
  background-color: transparent;
  padding: 0px;
}
</style>
